<template>
  <div v-if="$schoolName" class="sidebar-panel">
    <div class="gull-brand text-center p-4 d-flex justify-content-center align-items-center">
      <router-link :to="{name: 'Dashboard' , params: {schoolName:$schoolName}}">
                <h3  style="color: white;"  v-if="settings.siteLogo == 'text' || settings.siteLogo == 'siteName'">{{title}}</h3>
        <img  class="bg-white rounded " v-if="settings.siteLogo == 'image'" :src="settings.imageLight"  width="100">
      </router-link>
      <div v-show="changeMenu" class="sidebar-compact-switch ml-auto"><span></span></div>
    </div>
    <div class="scroll-nav">
      <div class="side-nav">

        <div id="sidebarIcon">
          <i class="fa fa-spinner fa-spin text-white"></i>
        </div>

        <div id="sidebarMenu" class="main-menu">

          <ul class="metismenu" id="menu" v-if="menus && menus.length">
            <li  :id="index" :class="[menu.name == currentSidebar ? 'mm-active' : '', menu.id == 122 ? 'static-pages' :'']" class="Ul_li--hover " v-for="(menu, index) in menus" :key="index" @click.prevent="getStaticPages(menu, index)">

              <a v-if="(menu.children && menu.children.length) || menu.id == 122" :class="{'has-arrow': menus}" href="#">
                <i :class="menuIcons[index]" class="text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ menu.name }}</span>
                <!--                <div class="d-inline-block input-group-append" data-hint>-->
                <!--                    <span class="text-theme input-group-text"><i class="fas fa-info"></i></span>-->
                <!--                    <div class="hint text-theme text-center">-->
                <!--                        <h3><a :href="link+menu.description_link" target="_blank"> {{menu.name}}</a></h3>-->
                <!--                        <p><a :href="link+menu.description_link" target="_blank"> {{menu.description}} </a></p>-->
                <!--                    </div>-->
                <!--                </div>-->
              </a>

              <ul
                  :id="'sub'+index"
                  :class="menu.name == currentSidebar ? 'mm-show' : '' "
                  class="mm-collapse"
                  v-if="(menu.children && menu.children.length) || menu.id == 122"
                  @click.prevent="activateChildClass(menu.name, index)"
              >
                <li class="item-name text-initial" v-for="(branch, index2) in menu.children" :key="index2">
                  <router-link class="link" router-link-active :to="{name: branch.link , params: {schoolName:$schoolName}}">
                    <i class="fas fa-link mr-2"></i>
                    <span class="text-muted">{{ branch.name }}</span>

                    <!-- here hint-->
                    <div  v-if="dashboardData.docManualsLinkActive == 1" data-hint>
                      <span class="text-theme input-group-text"><i class="fas fa-info"></i></span>
                      <div class="hint text-theme text-initial">
                        <h3 class="pt-1"> {{branch.name}}</h3>
                        <p class="m-0"> {{branch.description}} </p>
                        <a :href="link+branch.description_link" target="_blank" class="m-0 p-0">{{ $t('loadMore.text') }}</a>
                      </div>
                    </div>

                    <!--
                        <div  v-if="dashboardData.docManualsLinkActive == 1"   class="d-inline-block input-group-append" data-hint>
                            <span class="text-theme input-group-text"><i class="fas fa-info"></i></span>
                            <div class="hint text-theme text-center">
                                <h3><a :href="link+branch.description_link" target="_blank"> {{branch.name}}</a></h3>
                                <p><a :href="link+branch.description_link" target="_blank"> {{branch.description}} </a></p>
                            </div>
                        </div>
                    -->

                  </router-link>
                </li>
              </ul>

              <ul :id="'sub'+index" :class="menu.name == currentSidebar ? 'mm-show' : '' " class="mm-collapse static" v-if="menu.id == 122">
                <li class="item-name text-initial" v-for="(page, pageIndex) in pages" :key="pageIndex" >
                  <router-link class="link" router-link-active :to="{name: 'StaticPageShow' , params: {schoolName:$schoolName, id : page.id}}">
                    <i class="fas fa-link mr-2"></i> <span class="text-muted">{{pageIndex + 1}} - {{ page.pageTitle }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--            this route will move to db and come dynamic-->

<!--            <li>-->
<!--              <img class="p-2 bg-white my-3 rounded" :src="`${publicPath}assets/images/ultimate-transparent.png`" width="150" alt="">-->
<!--            </li>-->

          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import routes from '@/router/index.js';

export default {
  name: "sidebar",
  data() {
    return {
      docManualsLink: process.env.VUE_APP_DESC_LINK,
      link:'',
      settings:{
        siteLogo :"",
        settings:""
      },
      title:'',
      descriptionLink:'',
      description:'',
      currentSidebar : '',
      pages: [],
      publicPath: process.env.BASE_URL,
      menuData: [
        {
          main: 'Dashboard',
          sub: [
            {text: 'Dashboard', name: 'Dashboard'},
            {text: 'NewsBoard', name: 'News'},
            {text: 'Events', name: 'Event'},
            {text: 'Media', name: 'Media'},
            {text: 'Polls', name: 'Poll'},
            {text: 'School Terms', name: 'SchoolTerms'},
          ]
        }, {
          main: 'Administration',
          sub: [
            {text: 'Academic Years', name: 'AcademicYears'},
            {text: 'Admission Tests', name: 'Admissions'},
            {text: 'Principals', name: 'Principals'},
            {text: 'Coordinator of Department', name: 'HeadOfDepartment'},
            {text: 'Teacher Preparing', name: 'TeacherPreparing'},
            {text: 'Educational levels', name: 'EducationalLevels'},
            {text: 'Extra Fields', name: 'ExtraFields'},
            {text: 'Attached files', name: 'Attatchment'},
            {text: 'Student identifier', name: 'Identifier'},
            {text: 'Classwork classification', name: 'YearWorks'},
            {text: 'Evaluation classwork classification', name: 'KGYearWorks'},
            {text: 'Building', name: 'Dormitories'},
            {text: 'Hostel', name: 'Hostel'},
            {text: 'Transportation', name: 'Transports'},
            {text: 'Teachers', name: 'Teachers'},
            {text: 'Replace Teacher', name: 'ReplaceTeacher'},
            {text: 'Subjects', name: 'Subjects'},
            {text: 'Grades', name: 'Grades'},
            {text: 'Classrooms', name: 'Sections'},
            {text: 'Students', name: 'Students'},
            {text: 'Parents', name: 'Parents'},
            {text: 'Grades Schedule', name: 'ClassSchedules'},
            {text: 'Covering Sessions', name: 'CoveringSessions'},
            {text: 'Study Material', name: 'StudyMaterial'},
            {text: 'Lessons', name: 'Lessons'},
            {text: 'Grading scale', name: 'GradingScales'},
            {text: 'Calender', name: 'Calender'}
          ]
        }, {
          main: 'Education',
          sub: [
            {text: 'Attendance', name: 'Attendance'},
            {text: 'Promotion', name: 'Promotion'}
          ]
        }, {
          main: 'Exams & Homework',
          sub: [
            {text: 'Memos', name: 'Memos'},
            {text: 'Homework', name: 'Homework'},
            {text: 'Homework Status', name: 'HomeworkStatus'},
            {text: 'Assignments', name: 'Assignments'},
            {text: 'Assignment Status', name: 'AssignmentsStatus'},
            {text: 'OnlineExam Question Type', name: 'OnlineExamTypes'},
            {text: 'Online Exams', name: 'OnlineExams'},
            {text: 'Quizzes', name: 'Quizzes'}
          ]
        }, {
          main: 'Control',
          sub: [
            {text: 'add mark', name: 'SemesterDegree'},
            {text: 'semesters', name: 'Semester'},
            {text: 'Period exams', name: 'PeriodExams'},
            {text: 'Mark Sheet', name: 'ExamsList'},
            {text: 'Examination Halls', name: 'ExaminationHalls'},
            {text: 'seat numbers', name: 'SeatingNumbers'},
            {text: 'Security codes', name: 'SecurityNumbers'},
            {text: 'Examination Hall Supervisors', name: 'Supervisor'},
            {text: 'Examination Hall Attendance', name: 'AttendanceHall'},
            {text: 'Supervisors Report', name: 'SupervisorReport'},
            {text: 'Certificates', name: 'Certificates'}
          ]
        }, {
          main: 'Communication',
          sub: [
            {text: 'Messages', name: 'Messages'},
            {text: 'Approve teacher vacation', name: 'VacationTeacherApprove'},
            {text: 'Approve student vacation', name: 'VacationStudentApprove'},
            {text: 'Request Vacation', name: 'RequestVacation'},
            {text: 'My Vacation', name: 'MyVacation'},
            {text: 'Mail / SMS Templates', name: 'MailsmsTemplates'}
          ]
        }, {
          main: 'Online lessons',
          sub: [
            {text: 'Zoom', name: 'Zoom'},
            {text: 'BigBlueButton', name: 'Meetings'},
          ]
        }, {
          main: 'Library',
          sub: [
            {text: 'Library', name: 'Library'},
            {text: 'library Subscription', name: 'LibSubscription'},
            {text: 'Library Issues', name: 'LibraryIssues'},
            {text: 'Book Return', name: 'LibraryReturn'},
          ]
        }, {
          main: 'Accounts',
          sub: [
            {text: 'Setup', name: 'Setup'},
            {text: 'Financial year', name: 'FinancialYear'},
            {text: 'Currencies', name: 'Currencies'},
            {text: 'Accounts groups', name: 'AccountsGroups'},
            {text: 'Debenture Types', name: 'DebentureTypes'},
            {text: 'Cost centers', name: 'CostCenters'},
            {text: 'Chart of Accounts', name: 'ChartAccounts'},
            {text: 'Opening balances', name: 'OpeningBalances'},
            {text: 'Payment Vouchers', name: 'PaymentVouchers'},
            {text: 'Order Payment Vouchers', name: 'OrderPayment'},
            {text: 'Receipt Vouchers', name: 'ReceiptVouchers'},
            {text: 'Order Receipt Vouchers', name: 'OrderReceipt'},
            {text: 'Journal Vouchers', name: 'JournalVouchers'},
            {text: 'Taxes', name: 'Taxes'},
            {text: 'Tax Authorities', name: 'Agencies'},
            {text: 'Tax allocations', name: 'TaxesAllocation'},
            {text: 'Tax Categories', name: 'TaxCategories'},
            {text: 'Fee Groups', name: 'FeeGroup'},
            {text: 'Fee Types', name: 'FeeType'},
            {text: 'Fee Discount', name: 'FeeDiscount'},
            {text: 'Invoices', name: 'Invoices'},
            {text: 'Closures', name: 'Closures'},
            {text: 'Reports- Account statement', name: 'AccountStatement'},
            {text: 'Report - Payments', name: 'Payment'},
            {text: 'Report - Receipts', name: 'Receipt'},
            {text: 'Report - Journal Voucher', name: 'Journal'},
            {text: 'Report - Profit/Loss', name: 'ProfitLoss'},
            {text: 'Report - Balance Sheet ', name: 'BalanceSheet'},
            {text: 'Report - Trial Balance', name: 'TrialBalance'},
            {text: 'Report - Journals Report', name: 'PublicJournal'},
            {text: 'Opening Balance Report', name: 'OpeningBalance'},
          ]
        }, {
          main: 'Settings',
          sub: [
            {text: 'General Settings', name: 'Settings'},
            {text: 'Mobile Notifications', name: 'MobileNotifications'},
            {text: 'Signatures', name: 'Signatures'},
          ]
        }, {
          main: 'Management',
          sub: [
            {text: 'Languages', name: 'Languages'},
            {text: 'Administrators', name: 'Administrators'},
            {text: 'DB Export', name: 'Dbexports'},
            {text: 'license', name: 'AddLicense'},
          ]
        }, {
          main: 'Static pages',
          sub: [
            {text: 'Control Pages', name: 'StaticPages'},
          ]
        }, {
          main: 'Reports',
          sub: [
            {text: 'Reports', name: 'Report'},
          ]
        }

      ],
      menuIcons: ["i-Bar-Chart", "i-Library", "i-Suitcase", "i-Computer-Secure", "i-Computer-Secure", "i-File-Clipboard-File--Text", "i-File-Clipboard-File--Text", "i-File-Horizontal-Text", "i-Double-Tap", "i-Safe-Box1", "i-Bar-Chart", "i-File-Horizontal-Text", "i-Library",],
      loading: false
    }
  },
  mounted() {
    this.getInfo()
    this.loadDashboard()

  },
  methods: {

    ...mapActions({
      loadDashboard: 'dashboard/loadDashboard',
    }),
    loadingMenu() {
      this.loading = true;
      var loading = true;
      setTimeout(function(){ loading = false }, 1000);
      this.loading = loading
    },
    getInfo() {
      this.$axios.get('/service/login/settings')
          .then((response) => {
            this.settings = response.data;
            if(this.settings.siteLogo == 'text') {
              this.title = this.settings.siteLogoAdditional;
            }
            if(this.settings.siteLogo == 'siteName') {
              this.title = this.settings.siteTitle;

            }
          });

    },
    getStaticPages(sidebar, elementId) {
      const parentElement = document.getElementById(elementId)
      const childElement = document.getElementById(`sub${elementId}`)
      this.currentSidebar = sidebar.name;
      if (parentElement.classList.contains('mm-active')) {
        parentElement.classList.remove('mm-active')
        childElement.classList.remove('mm-show')
        this.currentSidebar = ''
      }
      if (sidebar.id == 122)
        this.$axios.get('static/listAll?type=filter').then(response => {
          this.pages = response.data.pages;
        })
    },
    activateChildClass(sidebar, elementId) {
      const parentElement = document.getElementById(elementId)
      const childElement = document.getElementById(`sub${elementId}`)
      this.currentSidebar = '';
      if (!parentElement.classList.contains('mm-active')) {
        parentElement.classList.add('mm-active')
        childElement.classList.add('mm-show')
        this.currentSidebar = sidebar
      }
    },
    helpDescriptionLink(routeName) {
      this.$axios.get('description_link/'+routeName)
          .then(response => {
            this.getMetaLink= routes.options.routes[1].children
            this.descriptionLink = "";
            this.currentLang = response.data.currentLang;
            this.description = response.data.childrenMenue.description
            this.getMetaLink.forEach(item => {
              if (item.name === routeName) {
                this.link = item.meta.link;
                this.descriptionLink = this.docManualsLink + this.link + '/?lang=' + this.currentLang;
              }
            });

          });
    },
  },
  computed: {
    changeMenu() {
      return this.$store.state.dashboardData.leftmenuScroller == 'e';
    },
    ...mapState('dashboard', {
      menus: 'menus',
    }),
    ...mapState('dashboard', {
      dashboardData : 'dashboardData',
    }),
  },
  watch:{
    '$i18n.locale': function() {
      this.loadingMenu()
    }
  }
}
</script>

<style scoped>
.link {
  opacity: .8;
  font-size: 14px;
}
.router-link-active {
  opacity: 1;
}
.gull-brand img {
  background: #fff;
  padding: 5px;
}
[dir="rtl"] .metismenu .has-arrow:after {
  left: 5px !important;
  right: auto !important;
}
.metismenu .has-arrow:after {
  right: 5px !important;
}
[dir="rtl"] .sidebar-compact-switch.ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}
</style>