<template>

  <div>

    <div class="login-lang">
      <div class="btn-group">
        <i class="fas fa-globe-americas header-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
        <div  class="dropdown-menu dropdown-menu-right text-center" x-placement="top-start">
          <a v-for="language in languages" :key="language.id" @click.prevent="switchLocale(language)" class="dropdown-item text-start" href="#">{{ language.languageTitle }}</a>
        </div>
      </div>
    </div>


    <div v-if="checkLogin" class="row">
      <div class="col-md-6">
        <div class="p-4">
          
          <h1 class="mb-3 text-18 font-weight-bold">{{$t('signIn.text')}}</h1>
          <div v-if="invalidData == false" class="alert alert-warning" role="alert">
            {{$t('invalidUsernameOrPass.text')}}
          </div>

          <form @submit.prevent="signIn" novalidate class="text-initial">
            <div class="form-group">
              <label for="email">{{$t('userNameOrEmail.text')}}</label>
              <input class="form-control form-control-rounded" id="email" type="text" required v-model="form.email">
            </div>
            <div class="error" v-if="v$.form && v$.$dirty && v$.form.email.$errors.length">
              <div class=" "  v-for="error in v$.form.email.$errors" :key="error" style="color:red;">
                <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="password">{{$t('password.text')}}</label>
              <input class="form-control form-control-rounded" id="password" type="password" required
                     v-model="form.password">
            </div>
            <div class="error" v-if="v$.form && v$.$dirty && v$.form.password.$errors.length">
              <div class=" "  v-for="error in v$.form.password.$errors" :key="error" style="color:red;">
                <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
              </div>
            </div>
            <button class="btn btn-rounded btn-primary btn-block mt-2"

                    :disabled="buttonStatus">  {{ $store.state.buttonStatus ? $t('pleaseWait.text') : $t('signIn.text') }} <i v-if="$store.state.buttonStatus" class="fa fa-spinner fa-spin"></i></button>
          </form>
          <div class="mt-3 text-initial">
            <router-link class="text-muted" :to="{name : 'ForgetPassword'}">
              <u>{{$t('forgetPassword.text')}}</u>
            </router-link>
          </div>
          <div v-if="settings.contactUs == 1" class="mt-3 text-initial">
            <router-link class="text-muted" :to="{name : 'ContactUs'}">
              <u>{{$t('contactUs.text')}}</u>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 text-center small-bg" :style="{backgroundSize:'cover' ,backgroundImage: 'url(' + `${publicPath}assets/images/photo-long-3.jpg `+ ')' }">
        
        <div  class="p-3 text-center">
            <div v-if="settings.siteLogo == 'text' || settings.siteLogo == 'siteName'" class="font-weight-bold">{{title}}</div>
<!--            <div><img :src="`${publicPath}assets/images/ultimate-transparent.png`" width="150" alt="" class="mb-3"></div>-->
            <div><img v-if="settings.siteLogo == 'image'" :src="settings.imageLight" width="150" alt="" class="rounded"></div>
          </div>

        <div class="auth-right">
          <!--        <a class="btn btn-rounded btn-warning btn-email btn-block btn-icon-text text-white"><i class="i-Mail-with-At-Sign"></i> Sign up with Email</a>-->
          <!--        <a class="btn btn-rounded btn-google btn-block btn-icon-text text-white"><i class="i-Google-Plus"></i> Sign up with Google</a>-->
          <!--        <a class="btn btn-rounded btn-block btn-icon-text btn-facebook text-white"><i class="i-Facebook-2"></i> Sign up with Facebook</a>-->
        </div>
      </div>
    </div>

    <div v-if="checkLicenseInvalid" class="py-5 px-3">

      <p class="mb-3 text-muted text-18">{{ message }}</p>


      <a class="btn btn-lg btn-success btn-rounded text-white" :href="register">{{$t('pleaseContactSiteAdministration.text')}}</a>
    </div>

  </div>
</template>


<script>
import { mapActions } from 'vuex';
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
export default {
  setup() {
    return {v$: useVuelidate()}
  },
  components: {},
  data() {
    return {
      form: {
        password:'',
        email:''
      },
      publicPath: process.env.BASE_URL,
      register: process.env.VUE_APP_REGISTER,
      checkLogin: false,
      checkLicenseInvalid: false,
      message: '',
      languages:[],
      settings:{
        siteLogo :"",
        settings:""
      },
      title:''
    }
  },
  validations() {
    return {
      form: {
        password:{required},
        email:{required}
      },

    }
  },

  mounted() {
    this.checkLicense();
  },

  methods: {
    ...mapActions({
      changeLanguageUniversal: 'dashboard/changeLanguageUniversal',
    }),
    switchLocale(language) {
      this.changeLanguageUniversal({i18n: this.$i18n, languageUniversal : language.languageUniversal})
      this.setDirection(language)
    },
    signIn() {
      this.v$.$validate() // checks all inputs
      if ( !this.v$.$error) {
        this.$store.dispatch('login', this.form)
      }
    },
    checkLicense() {
      this.$axios.get('/website/check')
          .then((response) => {
            console.log(response);
            if (response.data.message == 'Invalid Url') {
              this.checkLogin = false;
              this.checkLicenseInvalid = true;
              this.message = 'Invalid Url';
            } else {
              this.getInfo();
              this.checkLicenseInvalid = false;
            }
          });
    },
    getInfo() {
      this.$axios.get('/service/login/settings')
          .then((response) => {
            let lang = {
              languageUniversal : response.data.showLang,
              isRTL : response.data.isRTL
            };
             this.switchLocale(lang)
             this.languages = response.data.allLanguages;
             this.settings = response.data;
             if(this.settings.siteLogo == 'text') {
               this.title = this.settings.siteLogoAdditional;
             }
            if(this.settings.siteLogo == 'siteName') {
              this.title = this.settings.siteTitle;
            }
            let colorTheme = response.data.layoutColor;
            if(colorTheme == 'ultimate') {
              colorTheme = "gradient-purple-indigo";
            }
            let appAllWrap = document.querySelector(".app-all-wrap");
            let body = document.querySelector("body");
            appAllWrap.setAttribute('data-theme-color', colorTheme);
            body.setAttribute('data-theme-color', colorTheme);
            this.checkLogin = true;

          });
    },
    setDirection(language) {
      let htmlEl = document.querySelector("html");
      let direction = 'ltr';
      if (language.isRTL) {
        direction = 'rtl';
      }
      localStorage.setItem('directionTheme', direction);
      htmlEl.setAttribute('dir', direction);
      htmlEl.setAttribute('lang', language.languageUniversal);
    }
  },
  computed: {
    invalidData() {
      return this.$store.state.loggedIn
    },
    buttonStatus() {
      return this.$store.state.buttonStatus
    },
  },
  watch: {
    $route() {

    },
  }
}
</script>

<style scoped>
.small-bg {
  background-position: bottom;
  background-size: cover;
}
.login-lang {position: fixed; right: 20px; top:20px; z-index: 9;}
[dir="rtl"] .login-lang {right: auto; left:20px}
.login-lang .fa-globe-americas {color: #fff; padding: 10px 20px; font-size: 20px;cursor: pointer;}
.auth-layout-wrap .auth-content {
    width: 100%;
    max-width: 700px !important;
}
</style>